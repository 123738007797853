<template>
    <div>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
        >
            <template v-slot:activator="{ on, attrs }">
                <ValidationProvider v-slot="{ errors }" :name="label" :rules="rules">
                    <v-text-field
                        v-model="dateFormatted"
                        v-bind:label="label"
                        v-bind:placeholder="placeholder"
                        v-bind:disabled="disabled"
                        v-bind:outlined="outlined"
                        v-bind:readonly="readonly"
                        v-bind:hide-details="hideDetails"
                        v-bind:dense="dense"
                        v-mask="'##/##/####'"
                        v-bind="attrs"
                        :error-messages="errors"
                        
                        @blur="date = parseDate(dateFormatted)"
                        v-on="on"
                        v-bind:showAppendOuter="showAppendOuter"
                        v-bind:nameIcon="nameIcon"
                        v-bind:textTooltip="textTooltip"
                    >
                        <template v-if="showAppendOuter" v-slot:append-outer>
                            <v-tooltip top class="pb-7">
                                <template v-slot:activator="{ on }">
                                    <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-1 pr-2 outlined_v_icon">{{nameIcon}}</v-icon>
                                </template>
                                <span class="pb-7 textTooltip" v-html="textTooltip"></span>
                            </v-tooltip>  
                        </template>
                    </v-text-field>
                </ValidationProvider>
            </template>
            <v-date-picker
                v-model="date"
                @input="menu = false"
                locale="es-MX"
                :allowed-dates="allowedDates"
                v-bind:min="min"
                v-bind:max="max"
                :color="colorDias"
                :header-color="colorHeader"
            ></v-date-picker>
        </v-menu>

       <!--  @change="date = parseDate(dateFormatted)" -->
     </div>                   
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
    components: {
        ValidationProvider,
    },
    props:{
        colorDias:{
            type:String,
            default:'primary'
        },
        colorHeader:{
            type:String,
            default:'primary'
        },
        nameIcon:{
            type:String,
            default:'info'
        },
        textTooltip:{
            type:String,
            default:''
        },
        showAppendOuter:{
            type: Boolean,
            default: false
        },
        rules: {
            type: [Object, String],
            default: ''
        },
        notAllowedDates: { 
            type: Array, 
            default: () => [] 
        },
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: undefined
        },
        outlined: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        hideDetails:{
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        min: {
            type: String,
            default: undefined
        },
        max: {
            type: String,
            default: undefined
        },
        valorDefecto:{
            type:String,
            default:undefined
        },
    },
    data: vm => ({
        date:null,
        dateFormatted: null,
        menu: false,
    }),
    watch: {
        date (val) {
            this.dateFormatted = this.formatDate(this.date);
            this.$emit("input",  this.date);
            this.$emit('updateNotAllowedDates');
        },
        valorDefecto(val){
            this.date = val;
        }
    },
    methods: {
        allowedDates(val){
            return (val == this.date || !this.notAllowedDates.includes(val) );
        },
        formatDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate (date) {
            if (!date) return null
            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        setDate(date){
          this.date = date;
        },
        resetDate(){
          this.date = null;
          this.dateFormatted = null;
        }
    },
    mounted() {
        this.date = this.valorDefecto;
    },
    
};
</script>